<template>
  <viewcard--c
    :title="
      $route.params.id > 0 ? 'Atualizar Checklist' : 'Cadastrar Checklist'
    "
    :btsave="$route.params.id > 0 ? btedit : btcreate"
    :btdelete="null"
    :btback="{}"
    :busy="loading"
    @clicked-save="validationForm"
    @clicked-delete="
      {
      }
    "
  >
    <hr class="p-0 m-0 mb-1" />
    <validation-observer ref="productsRules">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="6">
            <b-form-group label="Nome *">
              <validation-provider
                #default="{ errors }"
                name="Nome do Checklist"
                rules="required"
              >
                <b-form-input
                  v-model="record.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nome do Checklist"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <b-form-group label="Ativo">
              <b-form-checkbox
                class="custom-control-success mt-1"
                name="check-button"
                switch
                v-model="record.active"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <b-form-group label="Padrão">
              <b-form-checkbox
                class="custom-control-success mt-1"
                name="check-button"
                switch
                v-model="record.standard"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <b-form-group label="Central Tech">
              <b-form-checkbox
                class="custom-control-success mt-1"
                name="check-button"
                switch
                v-model="record.is_centraltech"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Tipo *">
              <validation-provider
                #default="{ errors }"
                name="Tipo de Checklist"
                rules="required"
              >
                <v-select
                  v-if="types.length > 0"
                  v-model="typesSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="types"
                  autocomplete="off"
                  :clearable="false"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6" v-if="record.is_centraltech">
            <b-form-group label="Turno *">
              <validation-provider
                #default="{ errors }"
                name="Turno do Checklist"
                rules="required"
              >
                <v-select
                  v-if="workshifts.length > 0"
                  v-model="workShiftsSelected"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="workshifts"
                  autocomplete="off"
                  :clearable="false"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <h5>Steps</h5>
        <b-button
          size="sm"
          variant="outline-primary"
          class="btn-tour-skip mr-1 mb-1"
          @click="onClickNew"
        >
          <feather-icon icon="PlusIcon" size="22" />
        </b-button>
        <!-- draggable -->
        <draggable
          :list="steps"
          tag="ul"
          group="people"
          class="list-group list-group-flush cursor-move"
        >
          <div
            class="list-group-item"
            v-for="(element, index) in steps"
            :key="index"
          >
            <div class="d-flex">
              <div class="ml-25">
                <b-card-text class="mb-0 font-weight-bold">
                  {{ element.step }}
                </b-card-text>
                <small>Ordenação: {{ index + 1 }}</small> <br />
              </div>
              <div class="ml-25">
                <feather-icon
                  :id="`edit-row-${index + 1}`"
                  icon="CopyIcon"
                  size="16"
                  class="mx-1"
                  v-b-tooltip.hover.top="'Editar'"
                  @click="editStep(element, index)"
                />
              </div>
              <div class="ml-25">
                <feather-icon
                  :id="`exclude-row-${index + 1}`"
                  icon="DeleteIcon"
                  size="16"
                  class="mx-1"
                  v-b-tooltip.hover.top="'Excluir'"
                  @click="deleteStep(index)"
                />
              </div>
            </div>
          </div>
        </draggable>
        <hr />
        <!-- Perguntas -->
      </b-form>
    </validation-observer>
    <b-modal
      id="modal-step"
      ref="modal-step"
      cancel-variant="outline-secondary"
      ok-title="Confirmar"
      cancel-title="Fechar"
      centered
      title="Step"
      @ok="onClickAddStep"
      @hide="onHideModal"
    >
      <b-form>
        <b-form-group>
          <b-form-input placeholder="Descreva" v-model="text_step" />
        </b-form-group>
      </b-form>
    </b-modal>
  </viewcard--c>
</template>
<script>
import _checklistService from "@/services/audiovisual/check-list-service";
import _workShiftsService from "@/services/audiovisual/work-shift-service";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import { required } from "@validations";
import draggable from "vuedraggable";
import { BListGroupItem, VBModal, VBTooltip } from "bootstrap-vue";
import { VBToggle } from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BListGroupItem,
    draggable,
    VBModal,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      btedit: { permission: `permission.audiovisual.demands.checklist.edit` },
      btcreate: {
        permission: `permission.audiovisual.demands.checklist.create`,
      },
      btdelete: {
        permission: `permission.audiovisual.demands.checklist.delete`,
      },
      loading: false,
      record: {
        id: 0,
        name: "",
        active: true,
        standard: false,
        type: 0,
      },
      types: [],
      steps: [],
      typesSelected: null,
      workshifts: [],
      workShiftsSelected: null,
      text_step: "",
      index_step: null,
    };
  },
  created() {
    localize("pt_BR", pt_br);
  },
  mounted() {
    this.getTypes();
    this.getWorkShifts();
  },
  methods: {
    validationForm() {
      this.$refs.productsRules.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    },
    getWorkShifts() {
      _workShiftsService
        .showAll()
        .then((res) => {
          this.workshifts = this.$utils.populardropdown(
            res.content,
            "name",
            "id",
            false,
            false
          );

          this.getRecord();
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    getTypes() {
      _checklistService
        .showAllTypes()
        .then((res) => {
          this.types = this.$utils.populardropdown(
            res.content,
            "name",
            "value",
            false,
            false
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    getRecord() {
      if (this.$route.params.id > 0) {
        this.loading = true;
        _checklistService
          .find(this.$route.params.id)
          .then((res) => {
            this.record = res.content;
            this.steps = res.content.listSteps.map((m) => {
              return {
                step: m.step,
                order: m.order,
              };
            });

            this.typesSelected = {
              label: this.returnType(res.content.type),
              value: res.content.type,
            };

            if (this.record.checkListWorkShifts && this.workshifts) {
              this.workShiftsSelected = this.record.checkListWorkShifts.map(
                (m) => {
                  return {
                    label: this.workshifts.filter(
                      (f) => f.value == m.workshift_id
                    )[0].label,
                    value: m.workshift_id,
                  };
                }
              );
              this.record.checkListWorkShifts = null;
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.loading = false));
      }
    },
    save() {
      if (
        !this.record.standard &&
        (this.steps == null || this.steps.length == 0)
      ) {
        this.$utils.toastError(
          "Notificação",
          "Necessário informar pelo menos um step."
        );
      } else if (this.record.standard && !this.record.active) {
        this.$utils.toastError(
          "Notificação",
          "Não é possível desativar um checklist padrão."
        );
      } else {
        this.record.listSteps = this.steps.map((m, index) => {
          return {
            step: m.step,
            order: index,
          };
        });

        this.record.type = this.$utils.getValueSelected(this.typesSelected);

        if (this.workShiftsSelected && this.record.is_centraltech) {
          this.record.checkListWorkShifts = [];
          this.workShiftsSelected.forEach((_ws) => {
            this.record.checkListWorkShifts.push({
              workshift_id: _ws.value,
              checklist_id: this.record.id
            });
          });
        }

        const payload = { data: { ...this.record } };

        //promisse
        const _createOrUpdate =
          this.record.id <= 0
            ? _checklistService.create(payload)
            : _checklistService.update(payload);

        this.loading = true;
        _createOrUpdate
          .then(() => {
            this.$utils.toast("Notificação", "Registro salvo com sucesso.");
            this.$router.go(-1);
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.loading = false));
      }
    },
    returnType(type) {
      return type === 0 ? "Início" : type === 1 ? "Execução" : "Finalização";
    },
    onClickNew() {
      this.text_step = "";
      this.index_step = null;
      this.$refs["modal-step"].show();
    },
    onHideModal(evt) {
      this.text_step = "";
      this.index_step = null;
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },
    onClickAddStep() {
      if (!this.text_step || this.text_step == " ") return false;
      if (this.index_step != null) {
        this.steps[this.index_step].step = this.text_step;
      } else {
        var order = this.steps.length + 1;
        this.steps.push({
          step: this.text_step,
          order: order,
        });
      }
    },
    editStep(element, index) {
      this.text_step = element.step;
      this.index_step = index;
      this.$refs["modal-step"].show();
    },
    deleteStep(index) {
      this.text_step = "";
      this.index_step = null;
      this.steps.splice(index, 1);
    },
  },
};
</script>